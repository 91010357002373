<template>
  <header
    id="header"
    class="header header-box-shadow-on-scroll header-bg-transparent header-abs-top header-show-hide"
    data-hs-header-options="{
      &quot;fixMoment&quot;: 1000,
      &quot;fixEffect&quot;: &quot;slide&quot;
    }"
  >
    <div class="header-section">
      <div id="logoAndNav" class="container">
        <nav class="js-mega-menu navbar navbar-expand-lg">
          <!-- Logo -->
          <router-link class="navbar-brand" :to="{ path: '/' }" aria-label="apidays.hk">
            <img src="@/assets/img/apidays-2023/images/apidays-2023-logo.png" alt="apidays.hk">
          </router-link>
          <!-- End Logo -->

          <button
            id="navHideButton"
            :aria-expanded="menu_expanded"
            type="button"
            class="navbar-toggler navbar-nav-wrap-navbar-toggler btn btn-icon btn-sm rounded-circle"
            aria-label="Toggle navigation"
            aria-controls="navBar"
            @click="expandMenu"
          >
            <span class="navbar-toggler-default">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
              </svg>
            </span>
            <span class="navbar-toggler-toggled">
              <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </span>
          </button>

          <!-- Navigation -->
          <div id="navBar" class="navbar-nav-wrap-navbar collapse navbar-collapse">
            <div class="navbar-body header-abs-top-inner">
              <ul class="navbar-nav">

                <li class="header-nav-item">
                  <router-link class="nav-link header-nav-link mr-0 mr-lg-3" :to="{ path: '/' }" @click.native="hideMenu('productsMegaMenu')">Home</router-link>
                </li>

                <!-- Conferences -->
                <li class="hs-has-sub-menu navbar-nav-item">
                  <a id="conferenceMegaMenu" class="hs-mega-menu-invoker nav-link mr-lg-2" href="javascript:;" aria-haspopup="true" aria-labelledby="conferenceSubMenu">Conferences <i class="fas fa-angle-down ml-2" /></a>
                  <div id="conferenceSubMenu" class="hs-sub-menu dropdown-menu" aria-labelledby="conferenceMegaMenu" style="min-width: 300px;">
                    <div class="hs-has-sub-menu">
                      <router-link id="navLinkapidays2023" class="dropdown-item" :to="{ path: '/2023' }" @click.native="hideMenu('conferenceMegaMenu')">Apidays Connect Hong Kong 2023 </router-link>
                      <a class="dropdown-item" href="https://www.benovelty.com/events/apidays-live-hongkong-2022" target="_blank" @click.native="hideMenu('conferenceMegaMenu')">Apidays Hong Kong 2022 <i class="fas fa-external-link-alt ml-2 small" /></a>
                      <a class="dropdown-item" href="https://www.benovelty.com/events/apidays-live-hongkong-2021" target="_blank" @click.native="hideMenu('conferenceMegaMenu')">Apidays Live Hong Kong 2021 <i class="fas fa-external-link-alt ml-2 small" /></a>
                      <a class="dropdown-item" href="https://www.benovelty.com/events/apidays-live-hongkong" target="_blank" @click.native="hideMenu('conferenceMegaMenu')">Apidays Live Hong Kong 2020 <i class="fas fa-external-link-alt ml-2 small" /></a>

                    </div>
                  </div>
                </li>
                <!-- End Conferences -->

                <li class="header-nav-item">
                  <router-link class="nav-link header-nav-link mr-0 mr-lg-3" :to="{ path: '/blog' }" @click.native="hideMenu('productsMegaMenu')">Blog</router-link>
                </li>

                <li class="header-nav-item">
                  <router-link class="nav-link header-nav-link mr-0 mr-lg-3" :to="{ path: '/press' }" @click.native="hideMenu('productsMegaMenu')">Press Room</router-link>
                </li>

                <!-- <li class="header-nav-item">
                  <router-link class="nav-link header-nav-link mr-0 mr-lg-3" :to="{ path: '/about' }" @click.native="hideMenu('productsMegaMenu')">Contact Us</router-link>
                </li> -->

                <!-- <li class="navbar-nav-last-item">
                  <a class="btn btn-sm btn-primary" href="https://app.moongate.id/events/apidays2023?utm_source=website&utm_medium=website" target="_blank">Get Ticket Now</a>
                </li> -->
              </ul>
            </div>
          </div>
          <!-- End Navigation -->

        </nav>
      </div>

    </div>
  </header>
</template>

<script>
const $ = require('jquery')
import HSHeader from '../assets/vendor/hs-header/src/js/hs-header'
import HSMegaMenu from '../assets/vendor/hs-mega-menu/src/js/hs-mega-menu'
import HSGoTo from '../assets/vendor/hs-go-to/src/js/hs-go-to'

export default {
  name: 'Header',
  components: {
  },
  data() {
    return {
      menu_expanded: false
    }
  },
  async mounted() {
    $('#header').each(function() {
      new HSHeader($(this)).init()
    })
    $('.js-mega-menu').each(function() {
      new HSMegaMenu($(this)).init()
    })
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
  },
  methods: {
    scrollToFooter() {
      window.scrollTo({
        top: $('#footer').offset().top,
        behavior: 'smooth'
      })
    },
    expandMenu() {
      this.menu_expanded = !this.menu_expanded
      if (this.menu_expanded) {
        $('#navBar').slideDown('fast', function() { $('#navBar').addClass('show'); $('#navBar').attr('style', null) })
      } else {
        $('#navBar').slideUp('fast', function() { $('#navBar').removeClass('show'); $('#navBar').attr('style', null) })
      }
      // $('#navHideButton').attr('aria-expanded', 'true')
      // this.menu_expanded = !this.menu_expanded
      // console.log($('#navBar'))
      // $('#navBar').collapse('show')
    },
    hideMenu(menuLabel) {
      // const megaMenu = $("[aria-labelledby='useCasesMegaMenu']")
      // console.log(megaMenu)
      // megaMenu.css('display', 'none')
      const isMobile = $('#navHideButton').css('display') === 'inline-flex'
      if (isMobile) {
        $('#navHideButton').click()
      } else {
        $("[aria-labelledby='" + menuLabel + "']").css('display', 'none')
        $('#navHideButton').click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("../assets/vendor/hs-mega-menu/dist/hs-mega-menu.min.css");
.dropdown-reverse {
  left: auto !important;
  right: 0px !important;
}

.force-hide {
  display: none !important;
}

#userProfileDropdown {
  top: 65px;
}
</style>
