import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import store from '../store'

export const constantRoutes = [
  {
    name: 'index',
    path: '/',
    component: () => import('@/views/index/index.vue')
  },

  {
    name: 'Apidays2023',
    path: '/2023',
    component: () => import('@/views/events/apidays-hongkong-2023/index.vue')
  },
  {
    name: 'transportation',
    path: '/2023/transportation',
    component: () => import('@/views/events/apidays-hongkong-2023/transportation/index.vue')
  },
  {
    name: 'sp-nginx',
    path: '/2023/nginx',
    component: () => import('@/views/events/apidays-hongkong-2023/sponsor/index.vue')
  },
  {
    name: 'sp-fabrixapi',
    path: '/2023/fabrixapi',
    component: () => import('@/views/events/apidays-hongkong-2023/sponsor/index.vue')
  },
  {
    name: 'sp-suse',
    path: '/2023/suse',
    component: () => import('@/views/events/apidays-hongkong-2023/sponsor/index.vue')
  },
  {
    name: 'sp-softwareag',
    path: '/2023/softwareag',
    component: () => import('@/views/events/apidays-hongkong-2023/sponsor/index.vue')
  },
  {
    name: 'lucky-draw',
    path: '/2023/lucky-draw',
    component: () => import('@/views/events/apidays-hongkong-2023/lucky-draw/index.vue')
  },

  {
    name: 'blog',
    path: '/blog',
    component: () => import('@/views/blog/index.vue')
  },
  {
    name: 'blog-post',
    path: '/blog/:url',
    component: () => import('@/views/blog/position/index.vue')
  },
  {
    name: 'press',
    path: '/press',
    component: () => import('@/views/press/index.vue')
  },

  // {
  //   name: 'blog1',
  //   path: '/blog/the-top-5-challenges-in-api-strategy',
  //   component: () => import('@/views/events/apidays-hongkong-2023/blog/the-top-5-challenges-in-api-strategy.vue')
  // },
  // {
  //   name: 'blog2',
  //   path: '/blog/the-top-5-reasons-to-join-apidayshk',
  //   component: () => import('@/views/events/apidays-hongkong-2023/blog/the-top-5-reasons-to-join-apidayshk.vue')
  // },

  {
    name: 'coupon-hkust-ec',
    path: '/coupon/hkust-ec',
    component: () => import('@/views/events/apidays-hongkong-2023/coupon/hkust-ec.vue')
  },

  {
    name: 'error404',
    path: '*',
    component: () => import('@/views/error404/index.vue')
  },

  {
    name: 'signup-confirmation',
    path: '/signup-confirmation',
    component: () => import('@/views/signup-confirmation/index')
  }
]

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()
router.beforeEach(async(to, from, next) => {
  console.log('setShowDummy', true)
  store.dispatch('app/setShowDummy', true)
  next()
})
router.afterEach((to, from) => {
  console.log('setShowDummy', false)
  store.dispatch('app/setShowDummy', false)
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
